/**
 * 选择图片
 * @returns {Promise<unknown>}
 */
export function selectFile(isFromCamera, accept = 'image/*') {
    return new Promise((resolve => {
        const inputDom = document.createElement('input')
        inputDom.style.position='absolute';
        inputDom.style.top="-5000px";
        inputDom.style.width='10px'
        inputDom.style.height='10px'
        if (isFromCamera) {
            inputDom.setAttribute('capture', "camera")
        }
        inputDom.setAttribute('multiple', 'true')
        inputDom.setAttribute('type', 'file')
        inputDom.setAttribute('accept', accept)
        inputDom.setAttribute('multiple', 'multiple')
        document.body.appendChild(inputDom)
        inputDom.click()
        inputDom.addEventListener('change', () => {
            resolve(inputDom.files)
            document.body.removeChild(inputDom)
        })
    }))
}
